/**
 *  pre
 * @type {{}}
 */
import Vue from "vue";
import VueLazyLoad from "vue-lazyload";
import { getPreHomeService } from "@/service/system-service";
import { userAccountSubscribeService } from "@/service/user-service";
import { mapMutations } from "vuex";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import { SOURCE_NAME, subscribeType } from "@/service/public/enum";
import Cookies from "js-cookie";
import { validateEmail, isPhone } from "@/utils/tools";
Vue.use(VueLazyLoad, {
  loading: require("@/assets/Loading/seize-seat-img.jpg")
});
const Pre = {
  name: "Pre",
  data() {
    return {
      preObj: {}, //pre数据
      isPhone: isPhone,
      brandWomenVisible: false,
      brandMenVisible: false,
      brandKidsVisible: false,
      brandHomeVisible: false,
      categoryWomenVisible: false,
      categoryMenVisible: false,
      categoryKidsVisible: false,
      categoryHomeVisible: false,
      subscribeStatus: false, //订阅状态
      emailTipsStatus: false,
      loadingStatus: false,
      account: "",
      accountPlaceholder: this.$t("login.account"),
      subscribeType: subscribeType, //订阅分类数据
    };
  },
  created() {
    this.onPreHome().then();
  },
  mounted() {
    document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
  },
  methods: {
    ...mapMutations("product", [
      "SET_MENU_TYPE",
      "SET_LABEL",
      "SET_MENU_ID",
      "SET_SOURCE",
      "SET_BRAND_ID",
      "SET_IS_NEW",
      "SET_IS_SALE",
      "SET_IS_OLD",
      "SET_LEVEL",
      "SET_SELECTION_ID"
    ]),
    /**
     * 跳转首页
     * @param cid
     */
    onLinks(cid) {
      this.SET_MENU_TYPE(cid);
      localStorage.setItem("cid", cid);
      Cookies.set("cid", cid, { expires: 1 });
      $event.$emit(EVENT_NAME.FOOTER_BANNER_QUERY);
      $event.$emit(EVENT_NAME.HEADER_MENU_TYPE);
      this.$router.push("/home/page");
    },
    /**
     * 跳转
     * @param obj
     * @param name
     * @param cid1
     */
    onLinkPage(obj, name, cid1) {
      this.SET_LABEL(obj.title);
      this.SET_MENU_TYPE(cid1);
      this.SET_MENU_ID(cid1);
      $event.$emit(EVENT_NAME.FOOTER_BANNER_QUERY);
      $event.$emit(EVENT_NAME.HEADER_MENU_TYPE);
      if (name === "brand") {
        this.SET_BRAND_ID(obj.brandId);
        this.SET_IS_NEW("");
        this.SET_IS_SALE("");
        this.SET_IS_OLD("");
        this.SET_LEVEL("");
        if (obj.selectionId) {
          this.SET_SELECTION_ID(obj.selectionId);
          this.SET_SOURCE(SOURCE_NAME.SUPER_SALE);
          if (this.$route.path === "/product/list") {
            $event.$emit(EVENT_NAME.PRODUCT_LIST_QUERY);
            $event.$emit(EVENT_NAME.PRODUCT_MENU_QUERY);
          } else {
            this.$router.push("/product/list");
          }
        } else {
          this.SET_SOURCE(SOURCE_NAME.MENU_LINK_LIST);
          if (this.$route.path === "/brands/list") {
            $event.$emit(EVENT_NAME.PRODUCT_BRANDS_LIST_QUERY);
            $event.$emit(EVENT_NAME.PRODUCT_BRANDS_MENU_QUERY);
          } else {
            this.$router.push("/brands/list");
          }
        }
      }
      if (name === "category") {
        if (obj.selectionId) {
          this.SET_SELECTION_ID(obj.selectionId);
          this.SET_SOURCE(SOURCE_NAME.SUPER_SALE);
        } else {
          this.SET_SOURCE(SOURCE_NAME.MENU_LINK_LIST);
        }
        // this.SET_TERTIARY_TYPE({
        //     categoryId:'',
        //     name:''
        // })
        if (this.$route.path === "/product/list") {
          $event.$emit(EVENT_NAME.PRODUCT_LIST_QUERY);
          $event.$emit(EVENT_NAME.PRODUCT_MENU_QUERY);
        } else {
          this.$router.push("/product/list");
        }
      }
    },
    onFocus() {
      this.accountPlaceholder = "";
    },
    onBlur() {
      this.accountPlaceholder = this.$t("login.account");
    },
    /**
     * 拉去pre数据
     * @returns {Promise<void>}
     */
    async onPreHome() {
      try {
        const { code, data } = await getPreHomeService();
        if (code === 1) {
          this.preObj = data;
          if (!isPhone()) {
            this.brandWomenVisible = true;
            this.brandMenVisible = true;
            this.brandKidsVisible = true;
            this.brandHomeVisible = true;
            this.categoryWomenVisible = true;
            this.categoryMenVisible = true;
            this.categoryKidsVisible = true;
            this.categoryHomeVisible = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 选中订阅
     */
     onSignupCheck(id) {
      for (let i in subscribeType) {
        if (id === subscribeType[i].value) {
          subscribeType[i].checkBox
            ? (subscribeType[i].checkBox = 0)
            : (subscribeType[i].checkBox = 1);
        }
      }
    },
    /**
     * 订阅消息
     * @returns {Promise<void>}
     */
    async onAccountSubscribe() {
      try {
        this.loadingStatus = true;
        let params = {
          email: this.account,
          home: subscribeType[3].checkBox,
          kids: subscribeType[2].checkBox,
          men: subscribeType[1].checkBox,
          women: subscribeType[0].checkBox
        };
        const { code } = await userAccountSubscribeService(params);
        if (code === 1) {
          this.subscribeStatus = true;
        }
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        console.log(error);
      }
    },
    /**
     * 提交选中订阅
     */
     onSignupSubmit() {
      if (!validateEmail(this.account)) {
        this.emailTipsStatus = true;
        return;
      }
      this.emailTipsStatus = false;
      this.onAccountSubscribe().then();
    }
  }
};
export default Pre;
