<template>
  <div class="layout-pre">
    <!--s: Banner list-->
    <div class="layout-pre-banner">
      <ul>
        <li
          :key="key"
          v-for="(preImages, key) in preObj.preImages"
          @click="onLinks(preImages.cid1)"
        >
          <img :src="preImages.coverImage" v-lazy="preImages.coverImage" />
          <h1 class="title">{{ preImages.coverTitle }}</h1>
        </li>
      </ul>
    </div>
    <!--e: Banner list-->

    <!--s: Content list-->
    <div class="layout-pre-content">
      <!--s: Brands-->
      <div class="content-list">
        <div class="list">
          <div class="box-btn" @click="brandWomenVisible = !brandWomenVisible" >
            <h1 class="title">{{ $t("pre.brandWomen") }}</h1>
            <i class="ivu-icon " :class="brandWomenVisible ?'ivu-icon-ios-arrow-up':'ivu-icon-ios-arrow-down'"></i>
          </div>
          <ul v-if="brandWomenVisible">
            <li :key="key" v-for="(brandWomen, key) in preObj.brandWomen">
              <a
                href="javascript:"
                @click="onLinkPage(brandWomen, 'brand', brandWomen.cid1)"
                >{{ brandWomen.title }}</a
              >
            </li>
          </ul>
        </div>
        <div class="list">
          <div class="box-btn" @click="brandMenVisible = !brandMenVisible" >
            <h1 class="title">{{ $t("pre.brandMen") }}</h1>
            <i class="ivu-icon " :class="brandMenVisible ?'ivu-icon-ios-arrow-up':'ivu-icon-ios-arrow-down'"></i>
          </div>
          <ul v-if="brandMenVisible">
            <li :key="key" v-for="(brandMen, key) in preObj.brandMen">
              <a href="javascript:" @click="onLinkPage(brandMen, 'brand', 2)">{{
                brandMen.title
              }}</a>
            </li>
          </ul>
        </div>
        <div class="list">
          <div class="box-btn" @click="brandKidsVisible = !brandKidsVisible" >
            <h1 class="title">{{ $t("pre.brandKids") }}</h1>
            <i class="ivu-icon " :class="brandKidsVisible ?'ivu-icon-ios-arrow-up':'ivu-icon-ios-arrow-down'"></i>
          </div>
          <ul v-if="brandKidsVisible">
            <li :key="key" v-for="(brandKids, key) in preObj.brandKids">
              <a
                href="javascript:"
                @click="onLinkPage(brandKids, 'brand', 3)"
                >{{ brandKids.title }}</a
              >
            </li>
          </ul>
        </div>
        <div class="list">
          <div class="box-btn" @click="brandHomeVisible = !brandHomeVisible" >
            <h1 class="title">{{ $t("pre.brandHome") }}</h1>
            <i class="ivu-icon " :class="brandHomeVisible ?'ivu-icon-ios-arrow-up':'ivu-icon-ios-arrow-down'"></i>
          </div>
          <ul v-if="brandHomeVisible">
            <li :key="key" v-for="(brandHome, key) in preObj.brandHome">
              <a
                href="javascript:"
                @click="onLinkPage(brandHome, 'brand', 4)"
                >{{ brandHome.title }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!--e: Brands-->

      <!--s: Category-->
      <div class="content-list" v-if="!isPhone()">
        <div class="list">
          <div class="box-btn" @click="categoryWomenVisible = !categoryWomenVisible" >
            <h1 class="title">{{ $t("pre.categoryWomen") }}</h1>
            <i class="ivu-icon " :class="categoryWomenVisible ?'ivu-icon-ios-arrow-up':'ivu-icon-ios-arrow-down'"></i>
          </div>
          <ul v-if="categoryWomenVisible">
            <li :key="key" v-for="(categoryWomen, key) in preObj.categoryWomen">
              <a
                href="javascript:"
                @click="onLinkPage(categoryWomen, 'brand', categoryWomen.cid1)"
                >{{ categoryWomen.title }}</a
              >
            </li>
          </ul>
        </div>
        <div class="list">
          <div class="box-btn" @click="categoryMenVisible = !categoryMenVisible" >
            <h1 class="title">{{ $t("pre.categoryMen") }}</h1>
            <i class="ivu-icon " :class="categoryMenVisible ?'ivu-icon-ios-arrow-up':'ivu-icon-ios-arrow-down'"></i>
          </div>
          <ul v-if="categoryMenVisible">
            <li :key="key" v-for="(categoryMen, key) in preObj.categoryMen">
              <a href="javascript:" @click="onLinkPage(categoryMen, 'brand', 2)">{{
                categoryMen.title
              }}</a>
            </li>
          </ul>
        </div>
        <div class="list">
          <div class="box-btn" @click="categoryKidsVisible = !categoryKidsVisible" >
            <h1 class="title">{{ $t("pre.categoryKids") }}</h1>
            <i class="ivu-icon " :class="categoryKidsVisible ?'ivu-icon-ios-arrow-up':'ivu-icon-ios-arrow-down'"></i>
          </div>
          <ul v-if="categoryKidsVisible">
            <li :key="key" v-for="(categoryKids, key) in preObj.categoryKids">
              <a
                href="javascript:"
                @click="onLinkPage(categoryKids, 'brand', 3)"
                >{{ categoryKids.title }}</a
              >
            </li>
          </ul>
        </div>
        <div class="list">
          <div class="box-btn" @click="categoryHomeVisible = !categoryHomeVisible" >
            <h1 class="title">{{ $t("pre.categoryHome") }}</h1>
            <i class="ivu-icon " :class="categoryHomeVisible ?'ivu-icon-ios-arrow-up':'ivu-icon-ios-arrow-down'"></i>
          </div>
          <ul v-if="categoryHomeVisible">
            <li :key="key" v-for="(categoryHome, key) in preObj.categoryHome">
              <a
                href="javascript:"
                @click="onLinkPage(categoryHome, 'brand', 4)"
                >{{ categoryHome.title }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!--e: Category-->
    </div>
    <!--e: Content list-->

     <!--s: Signup for-->
    <div class="layout-signup-right" v-if="isPhone()">
      <div class="signup-content-box" v-if="!subscribeStatus">
        <span class="tips" v-if="emailTipsStatus">{{
          $t("login.emailTips")
        }}</span>
        <div class="signup-email">
          <input
            type="text"
            v-model="account"
            class="input"
            @focus="onFocus"
            @blur="onBlur"
            :placeholder="accountPlaceholder"
          />
        </div>
        <div class="signup-label">
          <div class="box">
            <ul>
              <li
                :key="key"
                v-for="(subscribe, key) in subscribeType"
                @click="onSignupCheck(subscribe.value)"
              >
                <i
                  class="label-icon"
                  :class="subscribe.checkBox ? 'select' : 'selected'"
                ></i>
                <span
                  class="label-name"
                  :class="subscribe.checkBox ? 'title' : ''"
                  >{{ subscribe.label }}</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="signup-text" v-html="$t('signup.textTips')"></div>
        <Button
          class="pc-btn"
          :loading="loadingStatus"
          @click="onSignupSubmit"
        >
          <span v-if="!loadingStatus">{{ $t("signup.btn") }}</span>
          <span v-else>{{ $t("signup.btn") }}</span>
        </Button>
        <van-button
          class="h5-btn"
          :loading="loadingStatus"
          type="info"
          @click="onSignupSubmit"
        >
          <span v-if="!loadingStatus">{{ $t("signup.btn") }}</span>
          <span v-else>{{ $t("signup.btn") }}</span>
        </van-button>
      </div>
      <div class="signup-content-box signup-flax" v-if="subscribeStatus">
        <div class="signup-tips">
          <span class="tips-image"></span>
          <div class="tips-content">
            <div class="box">
              <h2 class="tips-title">{{ $t("signup.thanks") }}</h2>
              <p class="tips-text">{{ $t("signup.submitText") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--e: Signup for-->
  </div>
</template>
<script>
import Pre from "./pre";
export default Pre;
</script>
<style lang="less" scoped>
@import "pre";
</style>
